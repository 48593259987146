import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, ListItem, ListItemIcon, ListItemText, List, Drawer, Divider } from '@material-ui/core';
import { Menu, MeetingRoom, Home, Chat, CalendarToday } from '@material-ui/icons';

import { withSnackbar } from 'notistack';
import CalendarTable from './calendar/CalendarTable';
import PaperContainer from './component/PaperContainer';
import LINETable from './LINE/LINETable';
import SubscribePage from './LIFF/Subscribe';
import TesUrl from './LIFF/tesurl';
import PaymentOverview from './payment/PaymentOveview';

const styles = (theme) => {
  return {
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBarUser: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      title: {
        display: 'none'
      },
      appBarUser: {
        flexGrow: 1,
      }
    },
    drawer: {
      width: '20vw',
      maxWidth: '300px'
    },
    listItem: {
      textDecoration: 'none'
    }
  };
};

const pages = [
  {
    label: 'TesURL',
    path: '/liff/tesurl',
    icon: <Home />,
    hide: true,
    component: () => <TesUrl />
  },
  {
    label: 'Subscribe',
    path: '/liff/subscribe',
    icon: <Home />,
    hide: true,
    component: (firebase) => <SubscribePage firebase={firebase} />
  },
  {
    label: 'Home',
    path: '/',
    icon: <Home />,
    component: (firebase) => <PaperContainer><PaymentOverview firebase={firebase} /></PaperContainer>
  },
  {
    label: 'Chats',
    path: '/chats',
    icon: <Chat />,
    component: (firebase) => <PaperContainer><LINETable firebase={firebase} /></PaperContainer>
  },
  {
    label: 'Calendars',
    path: '/calendars',
    icon: <CalendarToday />,
    component: (firebase) => <PaperContainer><CalendarTable firebase={firebase} /></PaperContainer>
  }
]

class AdminMain extends React.Component {
  constructor() {
    super();
    this.state = {
      currentPage: 0,
      isDrawerOpen: false
    };
  }

  renderRoutes() {
    let routes = [];
    pages.forEach((page) => {
      routes.push(<Route exact key={page.path} path={page.path} component={() => page.component(this.props.firebase)} />)
    });
    return routes;
  }

  renderDrawer(listItemClass) {
    let drawerContent = [];
    pages.forEach((page, index) => {
      if (!page.hide) {
        drawerContent.push(
          <ListItem button key={page.label} component={Link} to={page.path}>
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText color='inherit' textDecoration='none' primary={page.label} />
          </ListItem>
        );
      }
    });

    return drawerContent;
  }

  toggleDrawer(open) {
    this.setState({
      isDrawerOpen: open
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Router basename="/app">
        <AppBar position='static'>
          <Toolbar>
            <Switch>
              <Route path='/liff' />
              <Route>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => this.toggleDrawer(true)}>
                  <Menu />
                </IconButton>
              </Route>
            </Switch>
            <Typography variant="h6" className={classes.title}>
              Calendar Bot Admin
            </Typography>
            <div className={classes.appBarUser}>
              <Typography>{this.props.firebase.auth().currentUser ? this.props.firebase.auth().currentUser.email : ''}</Typography>
              <Button color="inherit" onClick={() => this.props.firebase.auth().signOut()}><MeetingRoom /></Button>
            </div>
          </Toolbar>
        </AppBar>

        <Switch>
          <Route path='/liff' />
          <Route>
            <Drawer
              onClick={() => this.toggleDrawer(false)}
              open={this.state.isDrawerOpen}>
              <List className={classes.drawer}>
                <ListItem>
                  <ListItemText primary='Calendar Bot Admin' secondary='V 1.0' />
                </ListItem>
                <Divider />
                {this.renderDrawer(classes.listItem)}
              </List>
            </Drawer>
          </Route>
        </Switch>

        {this.renderRoutes()}
      </Router>
    );
  }
}

export default withSnackbar(withStyles(styles)(AdminMain));