import React from 'react';
import {
  Typography,
  Grid,
  List,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import config from 'config';
import AddDialog from './component/AddDialog';
import AccountSelector from './component/AccountSelector';
import AccountOptions from './component/AccountOptions';
import AdvancedOptions from './component/AdvancedOptions';

const styles = (theme) => {
  return (
    {
      mainContainer: {
        minHeight: '300px'
      },
      divider: {
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }
  );
}

class LINETable extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoadingAccount: true,
      isLoadingDelete: false,
      accountList: null,
      selectedAccount: null,
      modalOpen: false,
      liffEnabled: null,
    }
  }

  componentDidMount() {
    this.getLINE();
  }

  async getLINE() {
    console.log('getting data');
    const uid = this.props.firebase.auth().currentUser.uid;
    const snap = await this.props.firebase.firestore().collection('user').doc(uid).get();
    if (snap.exists) {
      const data = snap.data();
      const LINERef = data.refToLINE;
      if (LINERef) {
        console.log(LINERef);
        const LINEPromise = LINERef.map((item) => item.get());
        const AccountData = await Promise.all(LINEPromise);
        this.setState({
          accountList: AccountData.map((item) => {
            return {
              id: item.id,
              ...item.data()
            };
          }),
          isLoadingAccount: false,
        });
      }
    }
    console.log(this.state);
  }

  async selectAccount(item) {
    console.log(`Selecting ${item}`);
    this.setState((oldState) => {
      oldState.selectedAccount = oldState.accountList.find((data) => data.id === item);
      return oldState;
    });
  }

  async manipulateLINE(method, body) {
    this.props.enqueueSnackbar('Please wait', { variant: 'info' });

    try {
      const userToken = await this.props.firebase.auth().currentUser.getIdToken();
      const userId = this.props.firebase.auth().currentUser.uid;
      const LINEReq = await fetch(`${config.project.LINEUrl}/${userId}`, {
        method: method,
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if (LINEReq.ok) {
        this.props.enqueueSnackbar('Success', { variant: 'success' });
        this.setState({
          isLoadingAccount: true,
          selectedAccount: null
        });
        this.getLINE();
      } else {
        this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
    }
  }


  async onModalClose(LINEID, label) {
    this.setState({
      modalOpen: false,
    });
    if (LINEID && label) {
      await this.manipulateLINE('POST', {
        LINEID: LINEID,
        label: label ? label : ''
      });
    }
  }

  async updateSingleLiffConfig(LINEID, newStatus) {
    this.setState((oldState) => {
      oldState.selectedAccount.liff = newStatus;
      return oldState;
    })
    await this.manipulateLINE('PUT', {
      liff: newStatus,
      LINEID: LINEID
    });
  }

  async updateGlobalLiffConfig(newStatus) {
    await this.manipulateLINE('PUT', {
      liff: newStatus,
    });
  }

  async deleteAccount(LINEID) {
    this.setState({ isLoadingDelete: true });
    await this.manipulateLINE('DELETE', {
      LINEID: LINEID
    });
    this.setState({ isLoadingDelete: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Typography variant='h4' component='h1'>LINE Chat Configuration</Typography>
        <br />
        <Divider />
        <br />
        <Typography variant='h5'>Account</Typography>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={4}>
            <List>
              <AccountSelector
                isLoading={this.state.isLoadingAccount}
                accountList={this.state.accountList}
                accountSelect={this.selectAccount.bind(this)}
                openDialog={() => this.setState({ modalOpen: true })}
              />
            </List>
          </Grid>
          <Grid item xs={1}>
            <Divider className={classes.divider} orientation='vertical' />
          </Grid>
          <Grid item xs={7}>
            <AccountOptions
              onDelete={this.state.isLoadingDelete}
              deleteAccount={this.deleteAccount.bind(this)}
              selectedAccount={this.state.selectedAccount}
              updateLiff={this.updateSingleLiffConfig.bind(this)}
            />
          </Grid>
        </Grid>
        <AddDialog open={this.state.modalOpen} onClose={(id, label) => this.onModalClose(id, label)} />
        <br />
        <Divider />
        <br />
        <Typography variant='h5'>Global Settings</Typography>
        <Grid container className={classes.mainContainer}>
          <AdvancedOptions
            liffEnabled={this.state.liffEnabled}
            isLoading={this.state.isLoadingAccount}
            updateLiff={this.updateGlobalLiffConfig.bind(this)}
          />
        </Grid>

      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(LINETable));