/* global liff */
import React from 'react';
import {
  Typography,
  Divider,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Paper } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import config from 'config';

const styles = (theme) => {
  return ({
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5px',
      padding: '20px',
      width: '80vw',
      maxWidth: '1200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    item: {
      margin: '20px 20px',
      maxWidth: '500px',
      width: '80%',
      height: '36px'
    },
    title: {
      marginTop: '20px'
    }
  });
}

class SubscribePage extends React.Component {
  constructor() {
    super();
    this.state = {
      liffErr: '',
      label: 'Loading...',
      lineID: '',
      text: '',
      error: '',
      isLoading: true,
      disabled: true
    }
  }

  async isLineIDExist(lineID) {
    const uid = this.props.firebase.auth().currentUser.uid;
    const snap = await this.props.firebase.firestore().collection('user').doc(uid).get();
    let result = false;

    if (snap.exists) {
      const data = snap.data();
      const LINERef = data.refToLINE;
      if (LINERef) {
        const LINEPromise = LINERef.map((item) => item.get());
        const AccountData = await Promise.all(LINEPromise);
        AccountData.forEach(account => {
          if (account.id === lineID) {
            result = account.data().label;
          }
        });
      }
    }
    return result;
  }

  async manipulateLineID(method) {
    try {
      const userToken = await this.props.firebase.auth().currentUser.getIdToken();
      const userId = this.props.firebase.auth().currentUser.uid;
      const LINEReq = await fetch(`${config.project.LINEUrl}/${userId}`, {
        method: method,
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          LINEID: this.state.lineID,
          label: this.state.label || ''
        })
      });
      if (LINEReq.ok) {
        this.props.enqueueSnackbar('Success, please refresh', { variant: 'success' });
      } else {
        this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
    }
  }

  async submit() {
    if (this.state.disabled) {
      this.setState({ isLoading: true });
      await this.manipulateLineID('DELETE');
      this.setState({ isLoading: false });
    } else {
      if (this.state.label) {
        this.setState({ isLoading: true, disabled: true });
        await this.manipulateLineID('POST');
        this.setState({ isLoading: false });
      } else {
        this.setState({ error: 'Please fill this field' });
      }
    }
  }

  componentDidMount() {
    liff.init(
      async data => {
        let lineID;
        let text;
        let label = '';
        let disabled = false;

        if (data.context.groupId) {
          lineID = data.context.groupId;
          text = 'Add this group';
        } else if (data.context.roomId) {
          lineID = data.context.roomId;
          text = 'Add this mpc';
        } else {
          lineID = data.context.userId;
          text = 'Add my account'
        }
        
        const result = await this.isLineIDExist(lineID);
        
        if (result !== false) {
          label = result;
          disabled = true;
          text = 'Unsubscribe';
        }

        this.setState({ label, lineID, text, isLoading: false, disabled });
      },
      err => {
        this.setState({
          liffErr: err,
          disabled: true,
          text: 'Liff error'
        });
      }
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.liffErr) {
      return (
        <Typography
          className={classes.title}
          variant='h5'
          align='center'
          component='h1'>
          This page is only accessible via LINE
       </Typography>
      );
    } else {
      return (
        <div>
          <Typography
            className={classes.title}
            variant='h5'
            align='center'
            component='h1'>
            Account Configuration
        </Typography>
          <br />
          <Divider />
          <br />
          <Paper className={classes.container}>
            <TextField
              required
              autoFocus
              disabled={this.state.disabled}
              margin="dense"
              id="label"
              label="Label"
              type="text"
              value={this.state.label}
              onChange={(evt) => this.setState({ label: evt.target.value })}
              error={!!this.state.error}
              helperText={this.state.error}
              fullWidth
            />
            <Button
              className={classes.item}
              variant='contained'
              color='primary'
              disabled={this.state.isLoading}
              onClick={() => this.submit()}
            >
              {this.state.isLoading ? <CircularProgress size={18} /> : this.state.text}
            </Button>
          </Paper>
        </div >
      );
    }
  }
}

export default withSnackbar(withStyles(styles)(SubscribePage));