import React from 'react';
import {
    Typography
} from '@material-ui/core';

function TesUrl(props) {
    return (
        <div>
            <Typography>{window.location.href}</Typography>
            <br></br>
            <Typography>{navigator.userAgent}</Typography>
        </div>
    );
}

export default TesUrl;