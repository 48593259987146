import React from 'react';
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Switch
} from '@material-ui/core';

function getType(id) {
  if (id.startsWith("R")) {
    return "Multichat";
  } else if (id.startsWith("C")) {
    return "Group";
  } else if (id.startsWith("U")) {
    return "User";
  } else {
    return "Special ID";
  }
}

const useStyles = makeStyles((theme) => {
  return ({
    deleteButton: {
      backgroundColor: 'red',
      color: 'white',
      height: "36px",
      width: "80px",
      '&:hover': {
        background: "#cc0000",
      },
    }
  });
})

function AccountOptions(props) {
  const classes = useStyles();

  if (props.selectedAccount) {
    return (<div>
      <Typography variant="h5" align="center">{props.selectedAccount.label}</Typography>
      <br />
      <Grid container>
        <Grid item xs={2}>
          <Typography>ID</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>: {props.selectedAccount.id}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Type</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>: {getType(props.selectedAccount.id)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem disableGutters>
              <ListItemText primary='PopUp Message' />
              <Switch
                checked={props.selectedAccount.liff}
                onChange={(evt) => { props.updateLiff(props.selectedAccount.id, evt.target.checked) }}
              />
            </ListItem>
          </List>
          <br />
          <Button
            disabled={!!props.selectedAccount.channelSecret}
            variant="contained"
            onClick={() => props.deleteAccount(props.selectedAccount.id)}
            className={classes.deleteButton}>
            {props.onDelete ? <CircularProgress size={18} /> : 'Delete'}
          </Button>
        </Grid>
      </Grid>
    </div>
    );
  } else {
    return (<Typography align="center">Please select account on the left</Typography>);
  }
}

export default AccountOptions;