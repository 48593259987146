import React from 'react';

import 'typeface-roboto';

import { SnackbarProvider } from 'notistack';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import { FirebaseAuthProvider, IfFirebaseUnAuthed, IfFirebaseAuthed } from "@react-firebase/auth";

import { firebaseConfig } from "config";
import Login from './page/Login';
import AdminMain from './page/Main';
import { Typography } from '@material-ui/core';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true
    }
    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (<Typography>Please wait...</Typography>);
    } else {
      return (
        <SnackbarProvider
          autoHideDuration={3000}
        >
          <FirebaseAuthProvider firebase={firebase} >
            <IfFirebaseAuthed>
              <AdminMain firebase={firebase} />
            </IfFirebaseAuthed>
            <IfFirebaseUnAuthed>
              <Login firebase={firebase} />
            </IfFirebaseUnAuthed>
          </FirebaseAuthProvider>
        </SnackbarProvider>
      );
    }
  }
}

export default App;
