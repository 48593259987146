import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  makeStyles,
  CircularProgress,
  Button,
  Container
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return ({
    divider: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    button: {
      marginTop: '10px',
      marginRight: '20px',
      height: '36px',
      width: '160px',
    }
  });
})

const optionsDictionary = {
  'PopUp Message': 'If you enable this options, viewing detail calendar event won\'t make bot send you detailed event message. Instead, you will view the detailed event in your own integrated LINE browser. Should you want to share that detailed event in the same chat, you can do by tapping the share button.',
}

function AdvancedOptions(props) {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();

  const handleSubmit = async (status) => {
    setIsLoading(true);
    await props.updateLiff(status);
    setIsLoading(false);
  }

  if (props.isLoading) {
    return (<CircularProgress />);
  } else {

    let options = [];
    for (const option of Object.keys(optionsDictionary)) {
      options.push(
        <ListItem
          button
          key={option}
          selected={selectedOption === option}
          onClick={() => {
            setSelectedOption(option);
          }}>
          <ListItemText primary={option} />
        </ListItem>
      );
    }

    let explanation = <Typography align='center'>Please select option on the left</Typography>
    if (selectedOption) {
      explanation = <Container>
        <Typography>{optionsDictionary[selectedOption]}</Typography>
        {isLoading ?
          <React.Fragment>
            <br/>
            <CircularProgress />
          </React.Fragment>
          :
          <React.Fragment>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={() => handleSubmit(true)}
            >
              {isLoading ? <CircularProgress size={18} /> : 'Enable For All'}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
              onClick={() => handleSubmit(false)}
            >
              {isLoading ? <CircularProgress size={18} /> : 'Disable For All'}
            </Button>
          </React.Fragment>
        }
      </Container>
    }

    return (
      <React.Fragment>
        <Grid item xs={4}>
          <List>
            {options}
          </List>
        </Grid>
        <Grid item xs={1}>
          <Divider className={classes.divider} orientation='vertical' />
        </Grid>
        <Grid item xs={7}>
          {explanation}
        </Grid>
      </React.Fragment>
    );
  }
}

export default AdvancedOptions;