import React from 'react';
import {
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function AccountSelector(props) {
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  if (props.isLoading) {
    return (<CircularProgress />);
  } else {
    let accountList = [];
    if (props.accountList) {
      props.accountList.forEach((item, index) => {
        accountList.push(
          <ListItem
            button
            key={item.id}
            selected={selectedAccount === item.id}
            onClick={() => {
              const LINEID = props.accountList[index].id;
              setSelectedAccount(LINEID);
              props.accountSelect(LINEID);
            }}>
            <ListItemText primary={item.label ? item.label : ''} />
          </ListItem>
        );
      });

    }
    accountList.push(
      <ListItem
        button
        key='Add'
        onClick={props.openDialog}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary='Add' />
      </ListItem>
    )
    return accountList;
  }
}

export default AccountSelector;