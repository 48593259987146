import React from 'react';
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return ({
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '20px',
      padding: '20px',
      width: '80vw',
      maxWidth: '1200px'
    }
  });
});

export default function PaperContainer(props) {

  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      {props.children}
    </Paper>
  )
}