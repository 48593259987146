import React from 'react';
import { Avatar, Button, TextField, Typography, Container, CircularProgress, Link, CssBaseline } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';


const styles = (theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: '40px'
    },
    spinner: {
      colorPrimary: 'white'
    }
  };
};

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      loginError: {},
      isLoading: false,
      forgetPassword: false
    }
  }

  async login(email, password) {
    this.setState({
      loginError: {},
      isLoading: true
    });

    try {
      await this.props.firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.error(error);
      this.setState({
        loginError: error
      });
    }
    this.setState({
      isLoading: false
    });
  }

  async resetPassword(email) {
    this.setState({
      loginError: {},
      isLoading: true
    });

    try {
      await this.props.firebase.auth().sendPasswordResetEmail(email);
      this.setState({
        forgetPassword: false
      });
      this.props.enqueueSnackbar('Success, please check your email', { variant: 'success' })
    } catch (error) {
      console.error(error);
      this.setState({
        loginError: error
      });
    }

    this.setState({
      isLoading: false
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {this.state.forgetPassword ? 'Forget Password' : 'Login'}
          </Typography>
          <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
            <Typography
              align="center"
              color="error">
              {this.state.loginError.message}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!this.state.loginError.message}
              value={this.state.email}
              onChange={(text) => { this.setState({ email: text.target.value }) }}
            />
            {this.state.forgetPassword ? '' : <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!this.state.loginError.message}
              value={this.state.password}
              onChange={(text) => { this.setState({ password: text.target.value }) }}
            />}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (this.state.forgetPassword) {
                  this.resetPassword(this.state.email);
                } else {
                  this.login(this.state.email, this.state.password);
                }
              }}
            >
              {this.state.isLoading ? <CircularProgress color="inherit" size={20} /> : (this.state.forgetPassword ? 'Forget Password' : 'Login')}
            </Button>
            <Link onClick={() => { this.setState({ forgetPassword: !this.state.forgetPassword }) }} variant="body2">
              {this.state.forgetPassword ? 'Go back to login' : 'Forgot password?'}
            </Link>
          </form>
        </div>
      </Container>
    );
  }
}

export default withSnackbar(withStyles(styles)(Login));