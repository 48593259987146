import React from 'react';
import {
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import config from 'config';

const styles = (theme) => {
  return (
    {
      mainContainer: {
        minHeight: '300px'
      },
      saveButton: {
        minHeight: '40px'
      }
    }
  );
}


class CalendarAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoadingList: true,
      accountList: null
    }
  }


  componentDidMount() {
    this.getAllAccounts();
  }

  async getAllAccounts() {
    const uid = this.props.firebase.auth().currentUser.uid;
    console.log(uid);
    const snap = await this.props.firebase.firestore().collection('user').doc(uid).get();
    if (snap.exists) {
      const data = snap.data();
      console.log(data);
      const calendarRef = data.refToCalendar;
      if (calendarRef) {

        const calendarPromise = await Promise.all(calendarRef.map((item) => item.get()));
        const calendarList = calendarPromise.map((item) => item.data())

        if (calendarList.length > 0) {
          console.log(calendarList);
          this.setState({
            accountList: calendarList
          });
        }
      }
    }
    this.setState({
      isLoadingList: false
    });

  }

  async selectAccount(id) {
    await this.getAllAccounts();
    this.props.getCalendarData(id, this.state.accountList[id].calendarID);
  }

  render() {
    if (this.state.isLoadingList) {
      return (
        <CircularProgress />
      )
    } else {
      if (this.state.accountList) {
        let accountList = [];
        this.state.accountList.forEach((item, index) => {
          accountList.push(
            <ListItem
              button
              key={item.email}
              selected={this.props.selectedAccount === index}
              onClick={() => this.selectAccount(index)}>
              <ListItemText primary={item.email} />
            </ListItem>
          );
        })
        return accountList;
      } else {
        const uid = this.props.firebase.auth().currentUser.uid;
        return (
          <ListItem button onClick={() => window.open(`${config.project.authUrl}/${uid}`, "_blank")}>
            <ListItemText primary='Add account' />
          </ListItem>
        );
      }
    }
  }

}

export default withStyles(styles)(CalendarAccount);