import React from 'react';
import {
  Typography,
  CircularProgress,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import config from 'config';

const styles = (theme) => {
  return (
    {
      mainContainer: {
        minHeight: '300px'
      },
      saveButton: {
        minHeight: '40px'
      }
    }
  );
}

class CalendarSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoadingSave: false
    }
  }

  async saveCalendarOption() {
    this.setState({
      isLoadingSave: true
    });

    const selectedId = this.props.selectedCalendar;

    console.log(selectedId);

    try {
      const userToken = await this.props.firebase.auth().currentUser.getIdToken();
      const userId = this.props.firebase.auth().currentUser.uid;
      const calendarReq = await fetch(`${config.project.calendarUrl}/${userId}/${this.props.selectedAccount}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          calendarID: selectedId
        })
      });
      if (calendarReq.ok) {
        this.props.enqueueSnackbar('Success', { variant: 'success' });
      } else {
        this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      this.props.enqueueSnackbar('Fail, please try again', { variant: 'error' });
    }
    this.setState({
      isLoadingSave: false
    });
  }

  render() {
    const { classes } = this.props;

    if (this.props.selectedAccount || this.props.selectedAccount === 0) {
      if (this.props.isLoadingAccount) {
        return (<CircularProgress />)
      } else {
        let selectOptions = [];

        this.props.calendarList.forEach((item) => {
          selectOptions.push(
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              label={item.summary}
              disabled={this.state.isLoadingSave} />
          );
        });

        return (
          <FormControl component='fieldset'>
            <FormLabel>Selected calendar</FormLabel>
            <RadioGroup
              name='calendar'
              value={this.props.selectedCalendar}
              onChange={(evt) => this.props.updateSelectedCalendar(evt.target.value)}
            >
              {selectOptions}
            </RadioGroup>
            <Button
              variant='contained'
              color='primary'
              className={classes.saveButton}
              onClick={() => this.saveCalendarOption()}
              disabled={this.state.isLoadingSave}>
              {this.state.isLoadingSave ? <CircularProgress size={15} /> : 'Save'}
            </Button>
          </FormControl>
        );
      }
    } else {
      return (
        <Typography align='center'>Select account on the left</Typography>
      )
    }
  }
}

export default withSnackbar(withStyles(styles)(CalendarSelector));