import React from 'react';
import {
  Typography,
  Grid,
  List,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import CalendarSelector from './component/CalendarSelector';
import CalendarAccount from './component/CalendarAccount';

import config from 'config';

const styles = (theme) => {
  return (
    {
      mainContainer: {
        minHeight: '300px'
      },
      divider: {
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }
  );
}

class CalendarTable extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoadingList: true,
      isLoadingAccount: false,
      calendarList: null,
      selectedCalendar: null,
      selectedAccount: null,
    }
  }

  async getCalendarData(account, calendarID) {
    console.log(calendarID)
    this.setState({
      selectedAccount: account,
      selectedCalendar: calendarID,
      isLoadingAccount: true
    });
    try {
      const userToken = await this.props.firebase.auth().currentUser.getIdToken();
      const userId = this.props.firebase.auth().currentUser.uid;
      const calendarReq = await fetch(`${config.project.calendarUrl}/${userId}/${account}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      const calendarData = await calendarReq.json();
      console.log(calendarData)
      this.setState({
        calendarList: calendarData
      });
    } catch (error) {
      alert(error);
    }

    this.setState({
      isLoadingAccount: false,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Typography variant='h4' component='h1'>Calendar</Typography>
        <br />
        <Divider />
        <br />
        <Typography variant='h5'>Account</Typography>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={4}>
            <List>
              <CalendarAccount
                {...this.state}
                updateSelectedAccount={(value) => this.setState({ selectedAccount: value })}
                getCalendarData={(acc, id) => this.getCalendarData(acc, id)}
                firebase={this.props.firebase}
              />
            </List>
          </Grid>
          <Grid item xs={1}>
            <Divider className={classes.divider} orientation='vertical' />
          </Grid>
          <Grid item xs={7}>
            <CalendarSelector
              {...this.state}
              firebase={this.props.firebase}
              updateSelectedCalendar={(value) => this.setState({ selectedCalendar: value })}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(CalendarTable));