import React from 'react';
import {
  Typography,
  Divider,
  Grid,
  makeStyles,
  Link,
  CircularProgress,
  Button,
  Slider
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import config from 'config';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(1)
    },
    gopayButton: {
      width: '150px',
      height: '36px'
    }
  };
});

function PaymentOverview(props) {
  const classes = useStyles();
  const [remaining, setRemaining] = React.useState(0);
  const [payRedirect, setPayRedirect] = React.useState('');
  const [isLoadingRedirect, setIsLoadingRedirect] = React.useState(false);
  const [count, setCount] = React.useState(6);
  const [accountType, setAccountType] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const getUrl = async () => {
    setIsLoadingRedirect(true);
    const userToken = await props.firebase.auth().currentUser.getIdToken();

    const url = await (await fetch(`${config.project.paymentURL}/${count}/url`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }
    })).json();

    if (url.url) {
      setPayRedirect(url.url);
      window.location.href = url.url;
    } else {
      enqueueSnackbar(url.message, { variant: 'error' })
    }
    setIsLoadingRedirect(false);
  }

  React.useEffect(() => {
    const getRemaining = async () => {
      const uid = props.firebase.auth().currentUser.uid;
      const snap = await props.firebase.firestore().collection('user').doc(uid).get();
      if (snap.exists) {
        const data = snap.data();
        if (data.remaining === -99) {
          setRemaining('Unlimited');
        } else if (data.remaining === 0) {
          setRemaining('0');
        } else {
          setRemaining(data.remaining);
        }

        if (data.private) {
          setAccountType('private');
        } else {
          setAccountType('public');
        }
      }
    }
    getRemaining();
  });

  const redirectToPayment = () => {
    if (remaining !== 'Unlimited') {
      if (payRedirect) {
        window.location.href = payRedirect;
      } else if (!isLoadingRedirect) {
        getUrl();
      }
    } else {
      enqueueSnackbar('You are using unlimited account', { variant: 'warning' })
    }
  }

  return (
    <div>
      <Typography
        variant="h4"
        component="h1"
        className={classes.title}
      >Payment</Typography>
      <br />
      <Divider />
      <br />
      <Grid container alignItems='center'>
        <Grid md={6} xs={12} item>
          <Typography align='center' variant='h5'>{remaining || <CircularProgress />}</Typography>
          <Typography align='center' variant='h6'>Service day remaining</Typography>
        </Grid>
        <Grid md={6} xs={12} item>
          <Typography variant='h6'>Auto</Typography>
          <Slider
            value={count}
            onChange={(_, val) => { setCount(val) }}
            valueLabelDisplay='auto'
            min={1}
            max={12}
          />
          <Typography>Paying for {count} {count > 1 ? 'months' : 'month'}({count * 30} days)</Typography>
          <Typography>Rp. {config.price[accountType] ? config.price[accountType].price * count : '-'}</Typography>
          <br />
          <Button color='primary' variant='outlined' className={classes.gopayButton} onClick={() => { redirectToPayment(); }}>{isLoadingRedirect ? <CircularProgress size={15} /> : 'Pay with Gopay'}</Button>
          <br />
          <br />
          <Typography variant='h6'>Manual</Typography>
          <Typography>For manual payment please contact us on <Link href='http://nav.cx/65Pp6HB'>LINE</Link></Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default PaymentOverview;