import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button
} from '@material-ui/core';

function AddDialog(props) {
  const [uid, setUid] = React.useState('');
  const [label, setLabel] = React.useState('');
  const [error, setError] = React.useState('');

  const { onClose, open } = props;

  function handleClose(submit) {
    setError('');
    if (submit && !(uid && label)) {
      setError('Please fill all fields');
    } else {
      onClose(uid, label);
      setUid('');
      setLabel('');
    }
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Enter UID</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To get UID, type /id on the group or personal chat with bot
      </DialogContentText>
        <TextField
          required
          autoFocus
          margin="dense"
          id="label"
          label="Label"
          type="text"
          value={label}
          onChange={(evt) => setLabel(evt.target.value)}
          error={!!error}
          helperText={error}
          fullWidth
        />
        <TextField
          required
          margin="dense"
          id="uid"
          label="ID"
          type="text"
          value={uid}
          onChange={(evt) => setUid(evt.target.value)}
          error={!!error}
          helperText={error}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDialog;