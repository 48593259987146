const baseUrl = 'https://us-central1-calendar-bot-ec4bb.cloudfunctions.net';

module.exports = {
  firebaseConfig: {
    apiKey: "AIzaSyDVI2OJyRCugeERGrGrIOTZA3ulVxUPCzc",
    authDomain: "calendar-bot-ec4bb.firebaseapp.com",
    databaseURL: "https://calendar-bot-ec4bb.firebaseio.com",
    projectId: "calendar-bot-ec4bb",
    storageBucket: "calendar-bot-ec4bb.appspot.com",
    messagingSenderId: "616466818348",
    appId: "1:616466818348:web:bb8aed27050a9de6"
  },
  project: {
    authUrl: `https://calendar.randiny.id/auth/request`,
    calendarUrl: `${baseUrl}/admin/calendar`,
    LINEUrl: `${baseUrl}/admin/LINE`,
    paymentURL: `${baseUrl}/admin/payment`
  },
  bot: {
    channelAccessToken: 'hF8n1PO5716xSuZcXXayDGkFnTGpDSV5mCWUyZsP0LxayAOwtWc8/eocv7DF+X+GZmVKSKKGq1bRkh9Qm32IY8ZDHluHWCdqRdb3B6Uoe/UQrILlPAaHqOcaID/wu2oFNaDlbdFPk8fYSpG6Z6rHdQdB04t89/1O/w1cDnyilFU=',
    channelSecret: 'c5fe27b55e6bc5c0c9b6b7b1b6c501bf',
    maxFlex: 8000,
  },
  keywordMessage: {
    calendar: '/calendar',
    debug: '/debug',
    getid: '/id',
    detail: '/detail',
    status: '/status',
    config: '/config'
  },
  google: {
    client_id: '616466818348-hhhf7omu2fs0nb49s94m9idn51tcqmbu.apps.googleusercontent.com',
    client_secret: 'OzmimEIyOddaPxGsu5gNQn9S',
    project_id: "calendar-bot-ec4bb",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    redirect_uris: [
      'https://calendar.randiny.id/auth/handler'
    ]
  },
  functions: {
    corsOrigin: 'https://calendar-bot-ec4bb.web.app'
  },
  colorID: {
    1: '#7986cb',
    2: '#33b679',
    3: '#8e24aa',
    4: '#e67c73',
    5: '#f6c026',
    6: '#f5511d',
    7: '#039be5',
    8: '#616161',
    9: '#3f51b5',
    10: '#0b8043',
    11: '#d60000'
  },
  regex: {
    url: /\bhttps?:\/\/\S+/gi,
    detailKeyword: /upcoming#|ongoing#/gm
  },
  cron: {
    username: 'calendarbot',
    password: 'onlytrialtotestcronjobproperly'
  },
  welcomeEmail: {
    mailServer: {
      host: 'mail.randiny.id',
      port: 465,
      secure: true,
      auth: {
        user: 'calendar@randiny.id',
        pass: 'EnxyV64tJUKC2AGazJV8NQVVz03m8SIYi4mPe7xbJJw2RYsI2GsH3SZzuiCrQLj'
      }
    },
    standard: {
      subject: 'Welcome to Calendar Bot',
      textGenerator: (email, resetLink) => {
        return `
        <!DOCTYPE html>
        <html>
        
        <body>
          <img src="https://calendar.randiny.id/img/footer_logo.png" width="150px"/>
          <p>
            Hi ${email}, thank you for registering with Calendar Bot
          </p>
          <p>
            Your default password is pass1234. Change your password by clicking <a href="${resetLink}">here</a>
          </p>
          <p>
            Getting started :
            <ul>
              <li>Login to the dashboard at https://calendar.randiny.id/app</li>
              <li>Choose 'calendars' menu and login to your Google Calendar</li>
              <li>Add our bot at http://nav.cx/e2hOJWn</li>
              <li>Add bot to your group</li>
              <ul>
                <li>Automatic configuration (recommended) </li>
                <ul>
                  <li>Type /configure in your group</li>
                  <li>Open the link given by the bot</li>
                  <li>Login with your account</li>
                  <li>Give name and tap 'Add'</li>
                </ul>
                <li>Manual configuration</li>
                <ul>
                  <li>Type /id in your group</li>
                  <li>Open 'chats' menu in dashboard</li>
                  <li>Press 'Add'</li>
                  <li>Copy and paste GroupID to the add dialog</li>
                </ul>
              </ul>
            </ul>
          </p>
          <p>
            Hai ${email}, terima kasih telah mendaftarkan diri pada Calendar Bot
          </p>
          <p>
            Password Anda adalah pass1234. Ubah segera password Anda dengan klik <a href="${resetLink.replace('lang=en', 'lang=id')}">di sini</a>
          </p>
          <p>
          Cara menggunakan:
          <ul>
            <li>Login ke dashboard di https://calendar.randiny.id/app</li>
            <li>Pilih menu 'calendars' dan login ke Google Calendar</li>
            <li>Tambahkan bot kami di http://nav.cx/e2hOJWn</li>
            <li>Tambahkan bot ke grup yang kamu mau</li>
            <ul>
              <li>Konfigurasi otomatis (direkomendasikan) </li>
              <ul>
                <li>Ketik /configure di grup</li>
                <li>Buka link yang diberikan oleh bot</li>
                <li>Login</li>
                <li>Beri nama grup dan tekan 'Add'</li>
              </ul>
              <li>Konfigurasi manual</li>
              <ul>
                <li>Ketik /id di grup</li>
                <li>Buka menu 'chats' di dashboard</li>
                <li>Tekan 'Add'</li>
                <li>Masukan GroupID ke dialog yang muncul</li>
              </ul>
            </ul>
          </ul>
          </p>
        </body>
        
        </html>`;
      }
    }
  },
  liff: {
    config: 'line://app/1613118364-b7YJ5EWA',
    detail:'line://app/1613118364-174WG9BP'
  },
  midtrans: {
    sandbox: {
      serverKey: 'SB-Mid-server-2BI1O2cDfGm_xi8m1w6ACGqO',
      clientKey: 'SB-Mid-client-SP_f3-Kh7NXkjz3g'
    },
    production: {
      serverKey: 'Mid-server-9LinVLM9v4GVQx9d5MpofZa2',
      clientKey: 'Mid-client-Sa3l33jlpVIrWjtW'
    }
  },
  price: {
    public: {
      price: 5000,
      name: 'Public bot'
    },
    private: {
      price: 25000,
      name: 'Private bot'
    }
  }
}